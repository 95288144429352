import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<ResourceCard>`}</inlineCode>{` component should be wrapped with a `}<inlineCode parentName="p">{`<Column>`}</inlineCode>{` inside of
`}<inlineCode parentName="p">{`<Row className="resource-card-group">`}</inlineCode>{`. This allows the correct border
placement between a group of cards.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Row className="resource-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="With subtitle" title="Title" aspectRatio="2:1" actionIcon="arrowRight" href="https://gatsby-theme-carbon.now.sh" mdxType="ResourceCard">
          <img {...{
            "src": "/18f3a3dc6c5ae9221446f35a437b2780/adobe-icon.svg",
            "alt": "Adobe Acrobat icon"
          }}></img>
  </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Only subtitle" actionIcon="download" aspectRatio="2:1" href="https://gatsby-theme-carbon.now.sh" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "267px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAC4jAAAuIwF4pT92AAACQ0lEQVQ4y62UTUhUURiGv5lRWkQbaxNUqyAiBEMKiqI2RjWuWmRFzYw195YgYRBKUQimJAiBtWmRQSZUZIVCtGsZIhmlkUVhNhRSmvbDSDPN/Z6494zOWKNT6YGXc893zn3Oey/vOYJYXxHLQayf81ASsUCiL8U8LJjiLlAXSC5wQrIG89EU40uOT46aPmCD385RP5quR7PnZgNGs/qDICHw2bnr0+PoXEBXR2BRFexqhU3nQSozjtw+eBE2N5t1eR16biKwsg5+JPHatpa0o0NQ32VqT2IgYfDlBVoGuKoOPn0zL8c+G2fbW5huPUP/ARyPg6oB3O+HwZEMsPftPwJX1EI8AcNjcO1RBnSjFxyFvnd/C7QzwMkEvB+HJdUQG4eup7Cu3oAfD/8GNDnUvA5Hv4MchtJzsPwkmgZqzxDqAv026rMUn6Xq84ATuYNdeMxEZkOTCbJEUFeLq2FjE7r2rNlIKlWnFXGjNDlHsENpRVHPie1lT71amFRRjWpJo1LcAMUNSUoa0dVn+mY/en5z9NT8G6/uuHOBKj7IAX1TelqxbqLh9iSR6zjh9oHJCw+XZV8Of1wS6rpLb+DBxGbUH9ZO2aGD62sh1JHU/VdxKtr641uaixI1nZIXOAPmC9FdWK63pIxXJXVJIh2kXFj5paLEnsuS2nslILPBZkJtRiVEdyDIvcIgt6Us9br0FIQ7Bka2Nk7BCpx9bSIq0Y/5HI6lYXcLdmtXQdC549vJszXHn9P6YiknHnjOXJhT0Sa/ABpS760wObDoAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Mural icon",
              "title": "Mural icon",
              "src": "/static/cc31ca8ce56236accbb5ef95924419f3/ba499/mural-icon.png",
              "srcSet": ["/static/cc31ca8ce56236accbb5ef95924419f3/ba499/mural-icon.png 267w"],
              "sizes": "(max-width: 267px) 100vw, 267px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard subTitle="Alternate color" title="Dark" aspectRatio="2:1" color="dark" actionIcon="email" href="https://gatsby-theme-carbon.now.sh" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAADe0lEQVQ4y4WTT2hcdRDHv2/f1rpKFFsseO1JUFFviggVPBT/nISCeNAc2ibZl7XENGmOVvRQRWqoVYMigkYS9+1mN4sHDy0oNHYtBHe3hwaUmjZ2897L262m63Z3fzMy8162aQ/6g+E3zMz38+Y3wwPnABIrwqJFoMt7QcvJXfSr/TBVB/aZZbxKK0+N0cozb6lfG3hOc8vJ3R3eoxoqwGIXIBcxbBEJ/hrgL7GLzts/m1pyk6o726YCptp9TOvTTOunmGr3s8YkJzXn7TJ/hQf5m5ghQO3wB8viOYCzcOmczebiTjYVi6l6N1P9Q8PNs11unulS/aSh6j0kOamhJZs5iwJnAVpKYAtoS4BdjLMLpvKOjqnZhio20Z/HiZtnicICUbgQ+dfeJaokSWvKOzrkgtnFlMCEBS7o25+WBOUEaBtTBdPVCQEwhQvM4aKa+s0zTFenSGt+SRDlLFati2eFBa4gxXmscA7MefToApjWRomv/8jUKDE1v2eOTXyJaW7tCEmtaGLtb3wB96I3i6yZB5t5qyM3XXqFeKNE7H3L5M0x+3N6b/clxxslppUDFGu7cvdmUUA4jtNBBrzxJrqBA2p/PkBcShHlU8wLKaaF6N7ua66UoptfDFAwClKtMMYxgxsfAP4IvvPTYG8IncakzoPYBVFWfRm6Wjwr8TXfOAb2DqMjWn8E+dY0gGuvA423kfQdLAVOBG1/AuYiSMG5eFkyp1z8sSKo/SnYH0JHNL6DcjiFu+pvAPAd2L4D+KPYEzi47A+DG5Poaie5CEq3uiON5cCNY+hKbeBg1XfwkDIc2PDT26AOHgscbHpD4H8+Ro+Lfcj27rh9Gj2pCRy0fAdPKCONpNwRcORWIMhgv3w5nFCA2QL1n+/CyJy1uwxe6sOGI448Fd5hwB+8DXpIuzwFo7PcWk4RJJ1rdxmMbDXivQb4h1QXHXGCo0BwRL+SqA8C3hDeC8cV1JW5xfPshkd1syfWB6NaX7RjUWO3HQloMgOrfgC48jKwfhCzrZPaWUes9RHYO4j5Ky8A5lHVWDL74E5YH+r0zfp9H5AHEuEEfjJz8jeAw0mcKwPJy8/rUxPx/P77CNAbjgR/7Acu7sUD7RmstmewdukR7F59MYZFNbj52f8Aw7F482ng+nHYf58Abkzj8dY0ntx8H/jrHdi6uHQ0uzvPv7ZAHJs9yKMlAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Sketch icon",
              "title": "Sketch icon",
              "src": "/static/28b17f4b368ca58d4d47e5136b85f0d3/59afc/sketch-icon.png",
              "srcSet": ["/static/28b17f4b368ca58d4d47e5136b85f0d3/59afc/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ResourceCard title="Disabled card" aspectRatio="2:1" disabled href="https://gatsby-theme-carbon.now.sh" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAADe0lEQVQ4y4WTT2hcdRDHv2/f1rpKFFsseO1JUFFviggVPBT/nISCeNAc2ibZl7XENGmOVvRQRWqoVYMigkYS9+1mN4sHDy0oNHYtBHe3hwaUmjZ2897L262m63Z3fzMy8162aQ/6g+E3zMz38+Y3wwPnABIrwqJFoMt7QcvJXfSr/TBVB/aZZbxKK0+N0cozb6lfG3hOc8vJ3R3eoxoqwGIXIBcxbBEJ/hrgL7GLzts/m1pyk6o726YCptp9TOvTTOunmGr3s8YkJzXn7TJ/hQf5m5ghQO3wB8viOYCzcOmczebiTjYVi6l6N1P9Q8PNs11unulS/aSh6j0kOamhJZs5iwJnAVpKYAtoS4BdjLMLpvKOjqnZhio20Z/HiZtnicICUbgQ+dfeJaokSWvKOzrkgtnFlMCEBS7o25+WBOUEaBtTBdPVCQEwhQvM4aKa+s0zTFenSGt+SRDlLFati2eFBa4gxXmscA7MefToApjWRomv/8jUKDE1v2eOTXyJaW7tCEmtaGLtb3wB96I3i6yZB5t5qyM3XXqFeKNE7H3L5M0x+3N6b/clxxslppUDFGu7cvdmUUA4jtNBBrzxJrqBA2p/PkBcShHlU8wLKaaF6N7ua66UoptfDFAwClKtMMYxgxsfAP4IvvPTYG8IncakzoPYBVFWfRm6Wjwr8TXfOAb2DqMjWn8E+dY0gGuvA423kfQdLAVOBG1/AuYiSMG5eFkyp1z8sSKo/SnYH0JHNL6DcjiFu+pvAPAd2L4D+KPYEzi47A+DG5Poaie5CEq3uiON5cCNY+hKbeBg1XfwkDIc2PDT26AOHgscbHpD4H8+Ro+Lfcj27rh9Gj2pCRy0fAdPKCONpNwRcORWIMhgv3w5nFCA2QL1n+/CyJy1uwxe6sOGI448Fd5hwB+8DXpIuzwFo7PcWk4RJJ1rdxmMbDXivQb4h1QXHXGCo0BwRL+SqA8C3hDeC8cV1JW5xfPshkd1syfWB6NaX7RjUWO3HQloMgOrfgC48jKwfhCzrZPaWUes9RHYO4j5Ky8A5lHVWDL74E5YH+r0zfp9H5AHEuEEfjJz8jeAw0mcKwPJy8/rUxPx/P77CNAbjgR/7Acu7sUD7RmstmewdukR7F59MYZFNbj52f8Aw7F482ng+nHYf58Abkzj8dY0ntx8H/jrHdi6uHQ0uzvPv7ZAHJs9yKMlAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Sketch icon",
              "title": "Sketch icon",
              "src": "/static/28b17f4b368ca58d4d47e5136b85f0d3/59afc/sketch-icon.png",
              "srcSet": ["/static/28b17f4b368ca58d4d47e5136b85f0d3/59afc/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <h2>{`Code`}</h2>
    <Title mdxType="Title">Group</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/ResourceCard/ResourceCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard",
        "path": "components/ResourceCard/ResourceCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard"
      }}>{`<Row className="resource-card-group">
  <Column colMd={4} colLg={4} noGutterSm>
    <ResourceCard
      subTitle="Carbon Design System"
      href="https://www.carbondesignsystem.com">


![Github icon](/images/github-icon.png)

    </ResourceCard>

  </Column>
  <Column colMd={4} colLg={4} noGutterSm>
    <ResourceCard
      subTitle="Carbon Design System"
      href="https://www.carbondesignsystem.com">


![Github icon](/images/github-icon.png)

    </ResourceCard>

  </Column>
</Row>

`}</code></pre>
    <Title mdxType="Title">With title</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/ResourceCard/ResourceCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard",
        "path": "components/ResourceCard/ResourceCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard"
      }}>{`<Column colMd={4} colLg={4} noGutterSm>
  <ResourceCard
    subTitle="With subtitle"
    title="Title"
    aspectRatio="2:1"
    actionIcon="arrowRight"
    href="https://gatsby-theme-carbon.now.sh">


![Adobe Acrobat icon](/images/adobe-icon.svg)

  </ResourceCard>
</Column>

`}</code></pre>
    <Title mdxType="Title">Only subtitle</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/ResourceCard/ResourceCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard",
        "path": "components/ResourceCard/ResourceCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard"
      }}>{`<Column colMd={4} colLg={4} noGutterSm>
  <ResourceCard
    subTitle="Only subtitle"
    actionIcon="download"
    aspectRatio="2:1"
    href="https://gatsby-theme-carbon.now.sh">


![Mural icon](/images/mural-icon.png)

  </ResourceCard>
</Column>

`}</code></pre>
    <Title mdxType="Title">Dark</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/ResourceCard/ResourceCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard",
        "path": "components/ResourceCard/ResourceCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard"
      }}>{`<Column colMd={4} colLg={4} noGutterSm>
  <ResourceCard
    subTitle="Alternate color"
    title="Dark"
    aspectRatio="2:1"
    color="dark"
    actionIcon="email"
    href="https://gatsby-theme-carbon.now.sh">


![Sketch icon](/images/sketch-icon.png)

  </ResourceCard>
</Column>

`}</code></pre>
    <Title mdxType="Title">Disabled</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/ResourceCard/ResourceCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard",
        "path": "components/ResourceCard/ResourceCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/ResourceCard"
      }}>{`<Column colMd={4} colLg={4} noGutterSm>
  <ResourceCard
    title="Disabled card"
    aspectRatio="2:1"
    disabled
    href="https://gatsby-theme-carbon.now.sh"
  />
</Column>
`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use 32x32 image as child, will display in bottom left of card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set url for card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`subTitle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Smaller title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Large title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`launch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action icon, default is launch, options are `}<inlineCode parentName="td">{`launch`}</inlineCode>{`, `}<inlineCode parentName="td">{`arrowRight`}</inlineCode>{`, `}<inlineCode parentName="td">{`download`}</inlineCode>{`, `}<inlineCode parentName="td">{`disabled`}</inlineCode>{`, `}<inlineCode parentName="td">{`email`}</inlineCode>{`, `}<inlineCode parentName="td">{`calendar`}</inlineCode>{`,`}<inlineCode parentName="td">{`none`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`aspectRatio`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2:1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set card aspect ratio, default is 2:1, options are 1:1, 16:9, 4:3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`light`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set to `}<inlineCode parentName="td">{`dark`}</inlineCode>{` for dark background`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`disabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set for disabled card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add custom class name`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      